export const generatePassword = ({length = 12}) => 
{
    let seed        = 'abcdefghijklmnopqrstuvwxyz'+'ABCDEFGHIJKLMNOPQRSTUVWXYZ'+'0123456789!@#$%^&*'
    let password    = ""; 

    while (!validatePassword({password})){

        password ="";

        //shuffle
        seed = seed.split('')
        for (let i = seed.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [seed[i], seed[j]] = [seed[j], seed[i]]
        }
        seed = seed.join('');

        for(let i = 0; i < length; i++){
            const j = Math.floor(Math.random() * seed.length);
            password = password+seed[j]
        }

    }
    return password
}

export const validatePassword = ({password}) => 
{
    const hasLetters            = /[a-z]/.test(password);
    const hasUppercaseLetters   = /[A-Z]/.test(password);
    const hasNumbers            = /[0-9]/.test(password);
    const hasSymbols            = /[!@#$%^&*]/.test(password);

    return hasLetters && hasNumbers && hasSymbols && hasUppercaseLetters;
}
    
    