import { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useParams, Navigate, useSearchParams, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo-happier-header.png";
import Alert from "../../components/UI/Alert";
import Loader from "../../components/UI/Loader";
import UserContext from "../../context/UserContext";
import useChangeHandler from "../../hooks/useChangeHandler";
import { axiosInstance } from '../../services';
import useValidator from '../../hooks/useValidator';
import { generatePassword, validatePassword } from '../../utils/password';
const initialState = {
	'password': '',
	'newPassword': '',
	'confirmNewPassword': '',
}

const Login = () => {
	const navigate = useNavigate();
	const { corporateUrl } = useParams();
	const { setUser: setGlobalUser } = useContext(UserContext);

	const [loading, setLoading] = useState(true)
	const [foundCorporate, setFoundCorporate] = useState(true)
	const [showError, setShowError] = useState(false)

	const [companyLogo, setCompanyLogo] = useState(null)
	const [loginType, setLoginType] = useState(null)
	const [logoType, setLogoType] = useState("horizontal");

	/* Cambio de contraseña */
	const [formData, setFormData] = useState(initialState);
	const [validator, showValidationMessage] = useValidator()
	const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  	const [errorAlertText, setErrorAlertText] = useState(null);
	const [validationErrors, setValidationErrors] = useState({});
  	const [generatedPassword, setGeneratedPassword] = useState("");
	const [showChangePassword, setShowChangePassword] = useState(false);
	/***/


	const [user, setUser] = useState({
		password: "",
		email: "",
	});

	//SSO login variables
	const [callbackURL, setCallbackURL] = useState(null)
	const [showErrorSSO, setShowErrorSSO] = useState(false)
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const state = searchParams.get('state');
	const [corporateId, setCorporateId] = useState(null);

	const changeHandler = useChangeHandler(setUser)
	const passwordChangeHandler = useChangeHandler(setFormData)
	const location = useLocation();
	const [showNotFound, setShowNotFound] = useState(false);

	useEffect(()=>
	{
		if(location.state?.notFound == true){
			setShowNotFound(true)
		}

	},[])
	useEffect(() => {
		const controller = new AbortController();

		const checkCorporate = async () => {
			const companyUrl = localStorage.getItem('company')

			if (!companyUrl && !corporateUrl) {
				return setFoundCorporate(false)
			} else if (companyUrl && !corporateUrl) {
				return navigate(`/${companyUrl}/login`)
			}

			try {
				const response = await axiosInstance.get(`verify_corporate/${corporateUrl}`, { signal: controller.signal });
				if (response.status === 200) {

					if (response?.data?.logo_url) {
						setCompanyLogo(response?.data?.logo_url)
					}

					if (response?.data?.login_type) {
						setLoginType(response?.data?.login_type)
					}

					if (response?.data?.corporate_id){
						setCorporateId(response?.data?.corporate_id)
						localStorage.setItem('company_id', response?.data?.corporate_id)
					}

					if (response?.data?.logo_type){
						localStorage.setItem('logo_type', response?.data?.logo_type)
					}
					// Guardo la url de la empresa en el localStorage
					localStorage.setItem('company', corporateUrl)

					setLoading(false);
				}
			} catch (error) {
				console.log(error);
				setFoundCorporate(false)
			}
		}

		checkCorporate();

		return () => controller.abort();
	}, [corporateUrl])

	useEffect(()=>{
		setLogoType(localStorage.getItem('logo_type'))
	},[])

	// Si la url no matchea con una empresa, devuelvo 404
	if (!foundCorporate) return <Navigate to="/not-found" replace />;

	function validateForm() {
		return user.email.length > 0 && user.password.length > 0;
	}

	async function handleSubmit(event) {
		event.preventDefault();
		setShowError(false)
		try {
			const response = await axiosInstance.post('login', { ...user, url: corporateUrl });

			const loggedUser = response?.data?.user;

			if (loggedUser) {

				if(loggedUser.hash){

					const hash = loggedUser.hash
					const user_id = loggedUser.user_id
					const date = loggedUser.date
					localStorage.setItem('must_change_password', loggedUser.must_change_password)
					console.log(loggedUser.must_change_password)
					navigate(`/${corporateUrl}/login/passwordReset`, { state: { hash, user_id, date, corporateUrl } })

				}else{

					setGlobalUser(loggedUser);
					localStorage.setItem('logged', true);

					if (localStorage.getItem('afterLogin') != undefined) {
						const url = localStorage.getItem('afterLogin');
						localStorage.removeItem('afterLogin');
						navigate(url);
					} else {
						navigate('/home');
					}
				}

			}
		} catch (error) {
			console.log(error)
			setShowError(true)
		}
	}

	async function handleExternalSubmit() {
		try {

			//Reques a api para obtener datos
			const response = await axiosInstance.get('unilever/auth0/login', { 
				params: { 
					url: corporateUrl, 
					corporate_id: corporateId 
				} 
			});
			// console.log(response?.data)
			const { data } = response?.data;

			if (typeof data !== 'undefined') {

				window.location.replace(data);
				
			} else {
				setShowErrorSSO(true);
			}
		} catch (error) {
			console.log(error)
			setShowErrorSSO(true);
		}
	}

	function checkboxHandler(event) {
		event.preventDefault();

		const value = event.target.value;
	}


	const passToogleConfirm = (element, id) => {

		var input = document.getElementById(id);
		var icon = document.querySelectorAll('.form-item__input__icon_right');

		if (input.style[0] == "-webkit-text-security") {
		  input.style = "";
		  icon[element].className = 'form-item__input__icon_right ri-eye-line';

		} else {
		  input.style = "-webkit-text-security:disc";
		  icon[element].className = 'form-item__input__icon_right ri-eye-off-line';

		}

	  }

	  const generateStrongPassword = () => {
		setGeneratedPassword(generatePassword({}))

	  }

	  const copyPassword = () =>{
		navigator.clipboard.writeText(generatedPassword);
	  }

	  /****/

	return (
		loading ? <Loader />
			:
			<section style={{ backgroundColor: "#f5f5f5" }}>
				<section className="section section--login">
					<div className="section__holder section__holder--login">
						<div className="modal modal--login --active">
							<div className="modal modal--login --active --mb40">
								<div className="modal__inner modal__inner--login --active">
									<div id="js-modal-login" className="modal__item" style={{ display: (showChangePassword==1) ? 'none': '' }}>
										<div className={`logo --mb35 ${(logoType == "cuadrado") ? 'logo--big' : ''}`}>
											<img src={companyLogo ?? logo} alt="logo"/>
										</div>

										<h2 className="section__title --mb12">Ingresar al sistema</h2>

										{showNotFound && <Alert type="error" text="El usuario no fue encontrado en los registros de Happier.<br> Si el problema persiste, por favor comuniquese con un administrador." />}

												{(loginType === "interno" || loginType === "mixto") &&
												<>
												<p className="section__text --mb35">
													Ingresa con tu usuario y contraseña
												</p>
												<form
													onSubmit={handleSubmit}
													method="post"
													className="modal__form modal__form--login"
												>

													{showError && <Alert type="error" text="Email y contraseña inválidos" />}


													<div className="form-item">
														<label className="form-item__label">Usuario</label>
														<div className="form-item__input">
															<input type="text"
																id="email"
																name="email"
																className="input input--border"
																required
																onChange={(event) => {
																	changeHandler(event);
																}} />
															<span htmlFor="email" className="form-item__input__focus"></span>
														</div>
													</div>

													<div className="form-item">
														<label className="form-item__label">Contraseña</label>
														<div className="form-item__input">
															<input
																type="password"
																id="password"
																name="password"
																className="input input--border"
																required
																onChange={(event) => {
																	changeHandler(event);
																}} />
															<span htmlFor="password" className="form-item__input__focus"></span>
														</div>
													</div>
													{/* TODO: Revisar este bug.. */}
													{/* <div className="form-item">
													<label className="checkbox">Recordar contraseña
													<input
														type="checkbox"
														name="tipo-propiedad"
														data-id="tipo-propiedad|1"
														data-name="Casas"
														data-slug="casas"
														value="casas"
														checked="checked"
														onChange={(event) => {
														checkboxHandler(event);
														}}
													/>
													<span className="checkbox__checkmark"></span>
													</label>
													</div> */}

													<div className="form-item --center">
														<button
															type="submit"
															className="button button--primary button--big"
															data-sitekey=""
															disabled={!validateForm()}
														>
															<span>Continuar</span>
														</button>
													</div>

												</form>
												</>}
											{}
										{(loginType === "externo" || loginType === "mixto") &&
											<>
												{loginType === "mixto" &&
													<hr width="80%"/>
												}
												<div className="form-item --center">
													{showErrorSSO && <Alert type="error" text="ocurrió un error al intentar iniciar sesion" />}

													<button
														type="submit"
														className="button button--primary button--big"
														data-sitekey=""
														onClick={handleExternalSubmit}
													>
														<span>Continuar ingreso en Super Heroes</span>
													</button>
												</div>
											</>
										}
									</div>
								</div>
							</div>
						</div>
							<div className="footer__item --center">
								<div className="logo logo--small">
									<img src={logo} alt="Happier" />
								</div>
								<p className="footer__text">Copyright 2024 - Todos los derechos reservados - Potenciado por <strong>Fanbag</strong></p>
							</div>
					</div>
				</section>

			</section>
	);
}
export default Login;